import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
import Users from './user/pages/Users';

const NewPlace = React.lazy(() => import('./places/pages/NewPlace'));
const UserPlaces = React.lazy(() => import('./places/pages/UserPlaces'));
const UpdatePlace = React.lazy(() => import('./places/pages/UpdatePlace'));
const Auth = React.lazy(() => import('./user/pages/Auth'));

//<Navigate to="/" />
const  App = () => {
  const { token, login, logout, userId} = useAuth();

  let routes;
  if(token){
    routes = (
      <>
          <Route path={'/'} Component={Users} />
          <Route path={'/:userId/places'} Component={UserPlaces} />
          <Route path={'/places/new'} Component={NewPlace} /> {/*this is first because the next route is similar*/} 
          <Route path={'/places/:placeId'} Component={UpdatePlace} />
          <Route path="*" element={<Navigate to="/" replace />} />
      </>
    );
  }
  else{
    routes = (
      <>
          <Route path={'/'} Component={Users} />
          <Route path={'/:userId/places'} Component={UserPlaces} />
          <Route path={'/auth'} Component={Auth} />
          <Route path="*" element={<Navigate to="/auth" replace />} />
      </>
    );
  }

  return (
        <AuthContext.Provider value={{isLoggedIn: !!token, token: token, userId: userId, login: login, logout: logout }}>
            <Router>
              <MainNavigation />
              <main>      
                <Suspense fallback={<div className='center'><LoadingSpinner /></div>}>
                  <Routes> 
                    {routes}
                  </Routes> 
                </Suspense>
              </main>
          </Router>
        </AuthContext.Provider>
  );
}

export default App;
